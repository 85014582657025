<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>


  </section>
</template>

<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'Administrative',
  components: { BlueHeader },
  data: () => ({

    itemsHeader: [
      {
        name: 'Aseguradora',
        link: { name: 'modules.settings.administrative.aseguradora' },
        rol: Role.Configuraciones_Administrativo_Aseguradora,
      },
      {
        name: 'Contrato',
        link: { name: 'modules.settings.administrative.contrato' },
        rol: Role.Configuraciones_Administrativo_Contrato,
      },
      {
        name: 'Producto Bodega',
        link: { name: 'modules.settings.administrative.productoBodega' },
        rol: Role.Configuraciones_Administrativo_RelacionarProducto,
      },
      {
        name: 'Pagador Contrato',
        link: { name: 'modules.settings.administrative.pagadorContrato' },
        rol: Role.Configuraciones_Administrativo_PagadorContrato,
      },
      {
        name: 'Usuarios',
        link: { name: 'modules.settings.administrative.usuarios' }, 
        rol: Role.Configuraciones_Administrativo_Usuarios,
      },
      {
        name: 'Bodegas',
        link: { name: 'modules.settings.administrative.bodegas' }, 
        rol: Role.Configuraciones_Administrativo_Bodegas,
      },
      {
        name: 'Auditoria',
        link: { name: 'modules.settings.administrative.auditoria' },
        rol: Role.Configuraciones_Administrativo_Auditoria,
      },
      {
        name: 'Contrato Producto',
        link: { name: 'modules.settings.administrative.contratoProducto' },
        rol: Role.Configuraciones_Administrativo_ContratoProducto,
      },
      {
        name: 'Pagador',
        link: { name: 'modules.settings.administrative.pagador' },
        rol: Role.Configuraciones_Administrativo_Pagador,
      },
      {
        name: 'Pagador Aseguradora',
        link: { name: 'modules.settings.administrative.pagadorAseguradora' },
        rol: Role.Configuraciones_Administrativo_PagadorAseguradora,
      },
      {
        name: 'Productos',
        link: { name: 'modules.settings.administrative.productos' },
        rol: Role.Configuraciones_Administrativo_Productos,
      },
      {
        name: 'Taquillas',
        link: { name: 'modules.settings.administrative.taquillas' },
        rol: Role.Configuraciones_Administrativo_Taquillas,
      },
      {
        name: 'Usuario Auditoria',
        link: { name: 'modules.settings.administrative.usuarioAuditoria' },
        rol: Role.Configuraciones_Administrativo_UsuarioAuditoria,
      },
      {
        name: 'Usuario Aseguradora',
        link: { name: 'modules.settings.administrative.usuarioAseguradora' },
        rol: Role.Configuraciones_Administrativo_UsuarioAseguradora,
      },
      {
        name: 'Usuario Bodega',
        link: { name: 'modules.settings.administrative.usuariosBodegas' },
        rol: Role.Configuraciones_Administrativo_UsuariosBodegas,
      },
      {
        name: 'Médicos',
        link: { name: 'modules.settings.administrative.medicos' },
        rol: Role.Configuraciones_Administrativo_Medicos,
      },
      {
        name: 'Prestador',
        link: { name: 'modules.settings.administrative.prestador' },
        rol: Role.Configuraciones_Administrativo_Prestador,
      },
      {
        name: 'Aseguradora Prestador',
        link: { name: 'modules.settings.administrative.aseguradoraPrestador' },
        rol: Role.Configuraciones_Administrativo_AseguradoraPrestador,
      },
      {
        name: 'Norma Producto',
        link: { name: 'modules.settings.administrative.norma' },
        rol: Role.Configuraciones_Administrativo_Norma,
      },
      {
        name: 'Usuario Empresa',
        link: { name: 'modules.settings.administrative.usuario-empresa' },
        rol: Role.Configuraciones_Administrativo_UsuarioEmpresa,
      },
    ]
  }),
}
</script>

<style scoped>
.subHeader-content {
  width: 96%;
}
</style>